// eslint-disable-next-line import/prefer-default-export
const ROUTES_PATH = {
  LOGIN: '/iniciar-sesion',
  REGISTER: '/crear-cuenta',
  CONFIRMATION_EMAIL: '/confirmar-email',
  PASSWORD_RESET: '/nueva-contrasena',
  RECOVER_PASSWORD: '/recuperar-contrasena',
  RESEND_EMAIL_CONFIRMATION: '/reenviar-correo-confirmacion',
  CONFIGURATION: '/configuracion',
  LOGOUT: '/cerrar-sesion',
  CHOOSE_ACCOUNT: '/escoger-cuenta',
  ENTER_PIN: '/ingresar-pin',
  WORKSHOPS: '/talleres',
  DETAIL_WORKSHOP: (slug) => {
    if (!slug) {
      return '/taller/:title';
    }

    return `/taller/${slug}`;
  },
  BLOG: '/blog',
  DETAIL_BLOG: (slug) => {
    if (!slug) {
      return '/blog/:title';
    }

    return `/blog/${slug}`;
  },
  MY_WORKSHOPS: '/mis-talleres',
  MY_PROFILE: '/mi-perfil',
  CART: '/carrito',
  RESULT_PAYMENT: '/resultado-pago',
  FAMILY: '/biblioteca-familiar',
  INFORMATION: '/informacion',
  PACKS_QUANTITY: (qty) => {
    if (!qty) {
      return '/packs/:quantity';
    }

    return `/packs/${qty}`;
  },
  DETAIL_PACK: (slug, id) => {
    if (!slug || !id) {
      return '/pack/:title/:id';
    }

    return `/pack/${slug}/${id}`;
  },
  DETAIL_VIDEO_WORKSHOP: (slug) => {
    if (!slug) {
      return '/taller/:slug/video';
    }

    return `/taller/${slug}/video`;
  },
  DETAIL_LIVESTREAM: (slug) => {
    if (!slug) {
      return '/livestream/:slug/watch';
    }

    return `/livestream/${slug}/watch`;
  },  
  WORKSHOP_CATEGORY: (title, id) => {
    if (!title || !id) {
      return '/talleres/categoria/:title/:id';
    }

    return `/talleres/categoria/${title}/${id}`;
  },
};

export default ROUTES_PATH;
